// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
$primary: #765875;
$secondary: #8d8d8d;
$success: #008000;
$info: #929E91;
//$warning: #ef8143;
$danger: #DC3545;
//$light: #dfe6ee;
//$dark: #0f1319;

$font-family-base: -apple-system,BlinkMacSystemFont,Montserrat,Quicksand,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
//$font-family-base: Montserrat,Quicksand;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);
